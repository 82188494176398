import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SessionService } from './core/services/session.service';
import { Session, SessionInitializationResourceType } from './shared/models/identity.model';
import { firstValueFrom } from 'rxjs';
import { IdentityService } from './shared/services/identity.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilityText } from './core/models/utility-text';
import * as wjcCore from '@grapecity/wijmo';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	title: string = 'trovata-angular-app-lite';

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private sessionService: SessionService,
		private identityService: IdentityService
	) {
		this.sessionService.subscribeToSessionId();
		wjcCore.setLicenseKey(
			// eslint-disable-next-line max-len
			'uat.trovata.io|d4.trovata.io|nab.trovata.io|d5.trovata.io|d1.trovata.io|dev.nab.trovata.io|beta.trovata.io|d2.trovata.io|dev.trovata.io|small-business.trovata.io|d3.trovata.io|app.trovata.io|widget.trovata.io|widget-dev.trovata.io|w1.trovata.io,581972163338753#B0g7HLvlmLhRXY63mc49CNkxybp9SY4FmdvJHduQXY5JiOiMXbEJCLiMmbJBSY4FmdvJHViojIh94QiwiIzUzN8MzMzYTMycTOxgTNiojIklkIs4XXbpjInxmZiwiIxYHNyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQtBTcad5UI5GSKVET8kzKmZ7a4N4QUBjUoZDTwtiS7A7cQllSr9UT5cHeXJHU45kaIxkU4JjNwRTYzdXVqJmb8omMh3kRWlXUr2GcFFETKtiWzNFRCxEVFZjc9AXeaZkNKFETjtiQshnVIhXV9ATOrUXarlXYKlWV5cXWuNGRoxWdMt4SrA7aLVDVPlUM4VzNMtyZotGbRFzaP36LkpWUvRzb6FEdwIUNMpWauNzRPN7NCZFaP5WVtRHOnV6LyNXW9kHRoZHO7EzbSR4LPBXWXF7ZzZGeipGeRBDWPNDOmVWZrIjVtdWeMNVa8pXTuZja5ZzQxJVNO9GavUUMXllZnFVQ6QXWlF7bj5UU9AnMshUY4NnZ9QnSSFjS5d7cZpFTY9UeI5ERHZDWGd7Nr5Ueq54K9Ena7kGOWNWbwhDTORVdR9kRlRHSrIWczVXbC3GOFtSVyZlI0IyUiwiIChjQDZERxMjI0ICSiwSOzAzN8cjNyMTM0IicfJye=#Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI7IDMwATMgcjMyATNyAjMiojI4J7QiwiIvlmLhRXY63mc49SM7xybp9SY4FmdvJHduYXZk5CdldGZpdHLvlmLhRXY63mc49CdldGZpdHLvlmLhRXY63mc49CcwFGLvlmLhRXY63mc49yMkxybp9SY4FmdvJHduM7cl9WazVnYtwGbh56cs2WauEGdhZ7byRnL6VGZs2WauEGdhZ7byRnLyQGLvlmLhRXY63mc49SY4VmYs2WauEGdhZ7byRnLiFmbuYXZkxybp9SY4FmdvJHduEDZs2WauEGdhZ7byRnL5QGLvlmLhRXY63mcI73Y'
		);
	}

	async ngOnInit(): Promise<void> {
		try {
			const session: Session = await firstValueFrom(this.identityService.getSession());

			if (session.initializationParameters && session.initializationParameters.resourceType === SessionInitializationResourceType.connection) {
				this.router.navigate(['/main-menu/connections'], {
					queryParams: {
						connectionId: session.initializationParameters.resourceId,
						action: session.initializationParameters.action,
					},
				});
			}
		} catch (error) {
			this.snackBar.open(UtilityText.somethingWrongMsg, undefined, {
				duration: 6000,
			});
			throw error;
		}
	}
}
